import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFabricatorById } from '../lib/appwrite/api';

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Edit,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from '@syncfusion/ej2-react-grids';

import { purchaseOrderGrid } from '../constants/purchaseOrder';
import PurchaseOrderView from './PurchaseOrderView';
import { formatDate } from '../utils/utils';
import { Header } from '@syncfusion/ej2/navigations';

const FabricatorView = () => {
  const fabricatorId = useParams();
  const [fabricatorDetails, setFabricatorDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Delete'];
  const editing = { allowDeleting: true, allowEditing: true };
  const [purchaseOrdersGridData, setPuchaseOrdersGridData] = useState();
  const [viewOrder, setViewOrder] = useState({});
  const [purchaseOrdersGridDataToDisplay, setPurchaseOrdersGridDataToDisplay] =
    useState();

  const contextMenuItems = [
    'AutoFit',
    'AutoFitAll',
    'SortAscending',
    'SortDescending',
    'Copy',
    'Edit',
    'Delete',
    'Save',
    'Cancel',
    'PdfExport',
    'ExcelExport',
    'CsvExport',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fabricatorDetails = await getFabricatorById(
          fabricatorId.fabricatorId
        );
        setFabricatorDetails(fabricatorDetails);
        console.log('FabricatorDetails:, ', fabricatorDetails);
        const fabricatorsPurchaseOrdersGridData =
          fabricatorDetails.purchaseOrders.map((purchaseOrder) => {
            return {
              commonProductName: purchaseOrder.commonProductName,
              total: purchaseOrder.purchaseDetails.total,
              fabricator: fabricatorDetails.name,
              orderDate: formatDate(purchaseOrder.orderDate),
              expectedCompletionDate: formatDate(
                purchaseOrder.expectedCompletionDate
              ),
              status: purchaseOrder.status,
              productImage:
                purchaseOrder.product.images &&
                JSON.parse(purchaseOrder.product.images)[0],
              statusBg: '#8BE78B',
              productImages:
                purchaseOrder.product.images &&
                JSON.parse(purchaseOrder.product.images),
              purchaseDetails: purchaseOrder.purchaseDetails,
              instructions: purchaseOrder.instructions,
            };
          });
        setPuchaseOrdersGridData(fabricatorsPurchaseOrdersGridData);

        //Initially Displaying only open orders
        const openOrders =
        fabricatorsPurchaseOrdersGridData &&
        fabricatorsPurchaseOrdersGridData.filter(
            (item) => item.status === "In Queue"
          );
        setPurchaseOrdersGridDataToDisplay(openOrders);
        

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, []);

  const handleRowSelected = (args) => {
    // Handle row selected event here
    console.log('Row Selected:', args.data);
    setViewOrder((prevState) => ({
      ...prevState,
      view: true,
      order: args.data,
    }));
  };

  const onBack = () => {
    setViewOrder((prevState) => ({
      ...prevState,
      view: false,
    }));
  };
  const changeViewOrderType = (viewOrderType) => {
    console.log('Yo selected: ', viewOrderType);
    let purchaseOrdersGridDataToDisplay;
    switch (viewOrderType) {
      case 'All':
        purchaseOrdersGridDataToDisplay =
          purchaseOrdersGridData && purchaseOrdersGridData;
        break;
      default:
        purchaseOrdersGridDataToDisplay =
          purchaseOrdersGridData &&
          purchaseOrdersGridData.filter(
            (item) => item.status === viewOrderType
          );
        break;
    }
    console.log("Items on render: ",purchaseOrdersGridDataToDisplay)
    setPurchaseOrdersGridDataToDisplay(purchaseOrdersGridDataToDisplay);
  };

  return (
    <>
      {viewOrder.view ? (
        <PurchaseOrderView onBack={onBack} orderDetails={viewOrder.order} />
      ) : (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          {fabricatorDetails && <h1 className="text-3xl font-semibold text-gray-800 mb-4 font-serif text-center">{fabricatorDetails.name}</h1>}
    

          <div className="flex p-3">
            <button
              className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={() => changeViewOrderType('In Queue')}
            >
              Open
            </button>
            <button
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={() => changeViewOrderType('Closed')}
            >
              Closed
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={() => changeViewOrderType('All')}
            >
              All
            </button>
          </div>
          <div>
            <GridComponent
              id="gridcomp"
              dataSource={purchaseOrdersGridDataToDisplay}
              allowPaging
              allowSorting
              contextMenuItems={contextMenuItems}
              rowSelected={handleRowSelected}
            >
              <ColumnsDirective>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {purchaseOrderGrid
                  .filter((item) => item.field !== 'action')
                  .map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Sort,
                  ContextMenu,
                  Filter,
                  Page,
                  ExcelExport,
                  Edit,
                  PdfExport,
                ]}
              />
            </GridComponent>
          </div>
        </div>
      )}
    </>
  );
};

export default FabricatorView;
