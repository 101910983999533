import React, { useEffect, useState } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
  Search,
} from '@syncfusion/ej2-react-grids';
import { deleteProduct, getProducts } from '../../lib/appwrite/api';
import { contextMenuItems, productsGrid } from '../../constants/products';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Header } from '../../components';
import RButton from '../../components/RButton';
import { Link } from 'react-router-dom';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getProducts();
        setProducts(products);
        console.log('Products:, ', products);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, []);

  const editing = { allowDeleting: true, allowEditing: true };
  const toolbarOptions = ['Search'];
  //Following code searches grid on each key stroke
  let grid;
  const created = () => {
    document
      .getElementById(grid.element.id + '_searchbar')
      .addEventListener('keyup', () => {
        grid.search(event.target.value);
      });
  };

  // to Delete a product from grid
  const onDeleteProduct = async (productId, product) => {
    // Log parameters for debugging
    console.log('Mayank: ', productId, product);

    try {
      // Make the API call to delete the product
      const response = await deleteProduct(productId, product);

      // Check if the response is truthy and has a status of "ok"
      if (response && response.status === 'ok') {
        // Update the state using the callback form of setProducts
        // setProducts((prevProducts) =>
        //   prevProducts.filter((product) => product.$id !== productId)
        // );
        toast.success('Deleted Product Successfully!');
      } else {
        // Handle the case where the response status is not "ok"
        console.log('Deletion failed. Response:', response);
        toast.error('Failed to delete product. Please try again.');
      }
    } catch (error) {
      // Handle errors that occurred during the API call
      console.error('Error deleting product:', error);
      toast.error(
        'An error occurred while deleting the product. Please try again.'
      );
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {/* <Header title="Products" /> */}
      <div className="flex justify-end mb-3">
        <Link to="/create-product" target='_blank'>
          <RButton>Ceate New Product</RButton>
        </Link>
      </div>

      <GridComponent
        id="gridcomp"
        dataSource={products}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
        toolbar={toolbarOptions}
        ref={(g) => (grid = g)}
        created={created}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {productsGrid.map((item, index) =>
            item.field === 'actions' ? (
              <ColumnDirective
                key={index}
                {...item}
                onDeleteProduct={onDeleteProduct}
              />
            ) : (
              <ColumnDirective key={index} {...item} />
            )
          )}
        </ColumnsDirective>
        <Inject
          services={[
            //Resize,
            Sort,
            //ContextMenu,
            Filter,
            Page,
            ExcelExport,
            Edit,
            PdfExport,
            Search,
          ]}
        />
      </GridComponent>
    </div>
  );
};
export default Products;