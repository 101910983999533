import React, { useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';

const Switcher = (props) => {
  const [isChecked, setIsChecked] = useState(props.currentState);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    props.onChange();
  };

  const { currentColor } = useStateContext();

  return (
    <>
      <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
        <input
          type="checkbox"
          name="autoSaver"
          className="sr-only"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span
          className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 ${
            isChecked ? `bg-[#7352FF]` : 'bg-[#5d5db1]'
          }`}
        >
          <span
            className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
              isChecked ? 'translate-x-6' : ''
            }`}
          ></span>
        </span>
        <span className="label flex items-center text-sm font-medium text-black">
          {props.title} <span className="pl-1"> {isChecked ? 'On' : 'Off'} </span>
        </span>
      </label>
    </>
  );
};

export default Switcher;
