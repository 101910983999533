//  This is for uploading multiple products using csv

import { defaultTypeFormDataTemplate } from '../dtos/dtos';
import { createProductCategory } from '../lib/appwrite/api';

//  get categories name or create new category
async function getCategoriesForCSV(categoryNames, allCategories) {
  const resultCategories = await Promise.all(
    categoryNames.map(async (categoryName) => {
      const existingCategory = allCategories.find(
        (category) => category.name === categoryName,
      );

      if (existingCategory) {
        return existingCategory.$id;
      }
      try {
        const newCategory = {
          name: categoryName,
          products: [],
        };
        const newCategoryId = await createProductCategory(newCategory);
        return newCategoryId.$id;
      } catch (error) {
        console.error('Error creating new category:', error);
        // You might want to handle the error differently here if needed
        return null;
      }
    }),
  );
  // Filter out null values in case there was an error creating a category
  return resultCategories.filter((categoryId) => categoryId !== null);
}

//  This logic is tightly wounded to csv format's sequence and product Object being currently used.
async function createProduct(productLine, allCategories, allTypes) {
  console.log('trying to create product');
  const product = {
    productName: productLine[0],
    //  Work on it after MVP
    type: allTypes.find((type) => type.name === productLine[1]) || defaultTypeFormDataTemplate,
    categories: await getCategoriesForCSV(
      productLine[2].split(':'),
      allCategories,
    ),
    discount: productLine[3],
    inStore: productLine[5] === '1',
  };
  return product;
}

export const generateProductsArray = (e, allCategories, allTypes) => {
  return new Promise((resolve, reject) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const products = [];

    reader.onload = async (event) => {
      const content = event.target.result;
      const lines = content.split('\n');

      for (let i = 1; i < lines.length; ++i) {
        const productLine = lines[i].split(',');
        let product = {};
        const currentProductName = productLine[0];

        let productIndex = products.findIndex(
          (product) => product.productName === currentProductName
        );

        if (productIndex === -1) {
          product = await createProduct(productLine, allCategories, allTypes);
          products.push(product);
          productIndex = products.length - 1; // get the last index
        } else {
          updateProductType(products[productIndex], productLine);
        }

        updateProductVariant(products[productIndex], productLine);
      }
      resolve(products);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(file);
  });
};

//  This logic is tightly wounded to csv format's sequence and product Object being currently used.
function updateProductVariant(product, productLine) {
  let variation = {};
  variation[productLine[6]] = productLine[7];
  variation[productLine[8]] = productLine[9];
  variation.cost_price = productLine[10];
  variation.mrp = productLine[11];
  variation.selling_price = productLine[12];
  variation.stock = productLine[13];
  variation.sku = productLine[14];
  if (!product.variants) product.variants = [];
  product.variants.push(variation);
}

function updateProductType(product, productLine) {
  console.log('Product Created So far: ', product);
  product.type.variations[0].options.push(productLine[7]);
  product.type.variations[1].options.push(productLine[9]);
}

//  This function generate variants based on variation 1 and variation 2 in CreateProduct.jsx
export const generateVariants = (variations) => {
  const variants = [];

  // Iterate over the options of the first variation
  if (variations[0].options.length == 0) {
    for (let option of variations[1].options) {
      // Construct the variant object and push it to the variants array
      variants.push({
        [variations[1].name.toLowerCase()]: option,
        cost_price: '',
        mrp: '',
        selling_price: '',
        stock: 1,
        sku: '2352466',
      });
    }
    return variants;
  }

  if (variations[1].options.length == 0) {
    for (let option of variations[0].options) {
      // Construct the variant object and push it to the variants array
      variants.push({
        [variations[0].name.toLowerCase()]: option,
        cost_price: '',
        mrp: '',
        selling_price: '',
        stock: 1,
        sku: '2352466',
      });
    }
    return variants;
  }

  for (let option1 of variations[0].options) {
    // Iterate over the options of the second variation
    for (let option2 of variations[1].options) {
      // Construct the variant object and push it to the variants array
      variants.push({
        [variations[0].name.toLowerCase()]: option1,
        [variations[1].name.toLowerCase()]: option2,
        cost_price: '',
        mrp: '',
        selling_price: '',
        stock: 1,
        sku: '2352466',
      });
    }
  }
  return variants;
};

//  Given api response, this function filters necessary values and forward it to components.
export const parseProductFromApiResponse = (requestedProduct) => {
  try {
    return {
      $id: requestedProduct.$id,
      productName: requestedProduct.productName,
      type: requestedProduct.type && JSON.parse(requestedProduct.type),
      images:
        (requestedProduct.images && JSON.parse(requestedProduct.images)) || [],
      // categories: requestedProduct.categories.map((cat) => {
      //   return {
      //     $id: cat.$id,
      //     name: cat.name
      //   }
      // }),
      categories: requestedProduct.categories.map((cat) => cat.$id),
      //  categories: requestedProduct.categories,
      discount: requestedProduct.discount,
      description: requestedProduct.description,
      variants:
        requestedProduct.variants && JSON.parse(requestedProduct.variants),
      inStore: requestedProduct.inStore,
    };
  } catch (error) {
    console.error('Error parsing product:', error);
    return null; // or handle the error as appropriate for your application
  }
};

//  extract imageIdFrom url
export const extractImageId = (url) => {
  // Extract the code using a regular expression
  console.log('Image url is: ', url);
  const match = url.match(/\/files\/([^\/]+)\/preview/);

  // Check if there is a match and extract the code
  const imageId = match ? match[1] : null;
  console.log('Image Id is: ', imageId);
  return imageId;
};
