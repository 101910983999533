
import { IoMdContacts } from 'react-icons/io';
import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock } from 'react-icons/ai';
import { FiShoppingBag, FiEdit, FiPieChart, FiBarChart, FiCreditCard, FiStar, FiShoppingCart } from 'react-icons/fi';
import { BiColorFill } from 'react-icons/bi';

import { RiContactsLine, RiStockLine } from 'react-icons/ri';
import { BsKanban, BsBarChart, BsBoxSeam, BsCurrencyDollar, BsShield, BsChatLeft } from 'react-icons/bs';
export const sideBarLinks = [
    {
      title: 'Admin',
      links: [
        {
          name: 'Dashboard',
          icon: <FiShoppingBag />,
          link: 'dashboard'
        },
      ],
    },
  
    {
      title: 'Products',
      links: [
        {
          name: 'Products',
          icon: <AiOutlineShoppingCart />,
          link: 'products'
        },
        {
          name: 'Create Product',
          icon: <IoMdContacts />,
          link: 'create-product'
        },
        {
          name: 'Category',
          icon: <RiContactsLine />,
          link: 'product-categories'
        },
        {
          name: 'Type',
          icon: <RiContactsLine />,
          link: 'product-types'
        },
        {
          name: 'Print Barcode',
          icon: <RiContactsLine />,
          link: 'barcodes'
        },
      ],
    },
    {
      title: 'Purchase Orders',
      links: [
        {
          name: 'Create Purchase Order',
          icon: <FiPieChart />,
          link: 'create-purchase-order'
        },
        {
          name: 'Purchase Orders',
          icon: <AiOutlineStock />,
          link: 'purchase-orders'
        },
        {
          name: 'Fabricators',
          icon: <AiOutlineAreaChart />,
          link: 'fabricators'
        },
        {
          name: 'Create Fabric',
          icon: <FiPieChart />,
          link: 'create-fabric'
        },
        {
          name: 'Fabric',
          icon: <AiOutlineBarChart />,
          link: 'fabric'
        }
      ],
    },
    {
      title: 'Sales',
      links: [
        {
          name: 'sales',
          icon: <AiOutlineCalendar />,
          link: 'sales'
        },
        {
          name: 'invoices',
          icon: <BsKanban />,
          link: 'invoices'
        },
        {
          name: 'Sales Return',
          icon: <FiEdit />,
          link: 'sales-return'
        },
        {
          name: 'POS UI',
          icon: <BiColorFill />,
          link: 'pos-ui'
        },
        {
          name: 'POS Barcode',
          icon: <BiColorFill />,
          link: 'pos-barcode'
        },
      ],
    },
  
  ];