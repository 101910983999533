import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import noImage from "../assets/noImage.jpeg";

const colorPallete = [
  "#E74C3C",
  "#8E44AD",
  "#F39C12",
  "#3498DB",
  "#1ABC9C",
  "#28B463",
  "#F1C40F",
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const gridProductCategories = (props) => {
  const colors = colorPallete.length;
  const randomNum = Math.floor(Math.random() * colors);
  console.log("M: random number: ", randomNum);

  return (
    <div className="flex flex-wrap">
      {props.categories.map((category, index) => {
        return (
          <span
            key={index}
            style={{
              background: colorPallete[(randomNum + index) % colors],
            }}
            className="text-white py-1 px-2 capitalize rounded-2xl text-xs m-1"
          >
            {category.name}
          </span>
        );
      })}
    </div>
  );
};

export const priceTemplate = (props) => {
  const minPrice = props.variants[0]?.selling_price;
  const maxPrice = props.variants[props.variants.length - 1]?.selling_price;
  return (
    <div>
      Rs. {minPrice} - Rs {maxPrice}
    </div>
  );
};

export const stockTemplate = (props) => {
  const totalQty = props.variants.reduce(
    (acc, variant) => +acc + +variant.stock,
    0
  );
  return <div>{totalQty}</div>;
};

const optionsTemplate = (props) => {
  console.log("M: props expecting a function too: ", props);
  return (
    <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
      <p className="flex items-center gap-2 text-gray-700 hover:drop-shadow-xl text-xl cursor-pointer">
        <Link
          target="_blank"
          to={`/create-product/${props.$id}`}
          className="flex items-center gap-2 text-gray-700 hover:drop-shadow-xl text-xl cursor-pointer"
        >
          <span>
            <MdModeEdit />
          </span>
        </Link>
      </p>
      <p className="flex items-center gap-2 text-red-400 hover:drop-shadow-xl text-xl cursor-pointer">
        <span>
          <MdDelete
            onClick={() => props.column.onDeleteProduct(props.$id, props)}
          />
        </span>
      </p>
    </div>
  );
};

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl w-20 h-20 md:ml-3 object-cover"
      src={props.images && props.images.length > 0 && props.images[0] || noImage}
      alt="order-item"
    />
  </div>
);


export const productsGrid = [
  {
    headerText: "Image",
    template: gridOrderImage,
    textAlign: "Center",
    width: "120",
  },
  {
    headerText: "Product",
    field: "productName",
    textAlign: "Left",
    width: "150",
  },
  {
    headerText: "Categories",
    template: gridProductCategories,
    field: "OrderItems",
    textAlign: "",
    width: "200",
  },
  {
    template: priceTemplate,
    headerText: "Price",
    width: "100",
    textAlign: "Left",
  },
  {
    template: stockTemplate,
    headerText: "Stock",
    format: "C2",
    textAlign: "Center",
    editType: "numericedit",
    width: "100",
  },
  {
    field: "inStore",
    headerText: "In Store",
    width: "80",
    textAlign: "Center",
  },

  {
    field: "actions",
    template: optionsTemplate,
    headerText: "Actions",
    width: "100",
    textAlign: "Center",
  },
];
