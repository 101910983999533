import { imageUrlToBase64 } from "../../utils/purchaseOrders";
import { formatDate } from "../../utils/utils";

import React, { useEffect, useState } from "react";

const TextAreaOutput = ({ inputText }) => {
  const lines = inputText.split("\n");

  return (
    <div>
      {lines.map((line, index) => (
        <p className="p-3" key={index}>
          {line}
        </p>
      ))}
    </div>
  );
};

//export default TextAreaOutput;

export const FirstPage = (props) => {
  console.log("Props for firsttttt Page:", props);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const base64String = await imageUrlToBase64(
          props.orderDetails.productImage
        );
        setImageSrc(base64String);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, []);
  const orderDetails = props.orderDetails;
  return (
    <>
      <div className="opening-order p-3">
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <span className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              ORDER DATE : {formatDate(orderDetails.orderDate)}
            </span>
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <span className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              COMPLETION DATE :{" "}
              {formatDate(orderDetails.expectedCompletionDate)}
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center p-8">
          <span className="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2">
            {orderDetails.commonProductName}
          </span>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3">
            <span className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Product Image
            </span>
            <img src={imageSrc} />
          </div>
          <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0">
            <span className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Instructions
            </span>
            <TextAreaOutput inputText={orderDetails.instructions} />
            {/* <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: (orderDetails.instructions).replace(/\n/g, '<br>') }} /> */}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3 mb-6 md:mb-0">
            <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Size Qty Details
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
              {/* {orderDetails.purchaseDetails.sizeQtyDetails.map(
                (entry, index) => (
                  <div key={index} className="p-3 flex flex-col items-center">
                    <p className="border border-black rounded-tl rounded-tr items-center">
                      <span className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-20">
                        {entry.size}
                      </span>
                    </p>
                    <p className="border border-black rounded-bl rounded-br items-center">
                      <span className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-20">
                        {entry.qty}
                      </span>
                    </p>
                  </div>
                )
              )} */}

              {orderDetails.purchaseDetails.sizeQtyDetails.map(
                (entry, index) => {
                  if (entry.qty === 0) {
                    return null; // Skip rendering
                  }

                  return (
                    <div key={index} className="p-3 flex flex-col items-center">
                      <p className="border border-black rounded-tl rounded-tr items-center">
                        <span className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-20">
                          {entry.size}
                        </span>
                      </p>
                      <p className="border border-black rounded-bl rounded-br items-center">
                        <span className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-20">
                          {entry.qty}
                        </span>
                      </p>
                    </div>
                  );
                }
              )}
            </div>
            <p className="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2 text-right">
              Total: {orderDetails.purchaseDetails.total}
            </p>
          </div>
        </div>

        <div className="max-h-96 overflow-y-auto"></div>
      </div>
    </>
  );
};

export const ImageSection = (props) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const base64String = await imageUrlToBase64(props.url);
        setImageSrc(base64String);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [props.url]);
  return <img src={imageSrc} />;
};
