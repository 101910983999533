import React, { useState } from 'react'
import CreateProductTypeForm from '../../forms/products/CreateProductTypeForm';
import CreateProductCategoryForm from '../../forms/products/CreateProductCategoryForm';
import { useParams } from 'react-router-dom';

const CreateProductCategoryAndTypes = () => {
  const [classification,setClassification] = useState('category');
  const params = useParams();
  console.log("SHubham",params);
  function changeClassification(){
    if(classification==='category'){
      setClassification('type')
    }else{
      setClassification('category');
    }
  }
  return (
    <>
    <button onClick={changeClassification}>{classification}</button>
    {classification=="category"?<CreateProductCategoryForm />:<CreateProductTypeForm/>}
    
    </>
    
  )
}

export default CreateProductCategoryAndTypes;