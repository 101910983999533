import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';

function RButton({color,onClick,children}) {
 const {currentColor} = useStateContext();
  return (
    <button
      className={`text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
      type="button"
      onClick={onClick}
      style={{background:currentColor}}
    >
      {children}
    </button>
  );
}

export default RButton;
