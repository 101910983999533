import React, { useState, useEffect } from 'react';
import {
  createPurchaseOrder,
  getFabricators,
  getProducts,
  updatePurchaseOrder,
} from '../../lib/appwrite/api';
import { getSizeQtyObj } from '../../utils/purchaseOrders';

const CreatePurchaseOrderForm = ({ purchaseOrderData }) => {
  console.log('prop obtained: ', purchaseOrderData);
  const [products, setProducts] = useState();
  const [product, setProduct] = useState();
  const [fabricators, setFabricators] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseOrder, setPurchaseOrder] = useState(purchaseOrderData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPurchaseOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handlePurchaseQtyChange(e, index) {
    const { name, value } = e.target;
    console.log(name, value, index);

    const totalQty = purchaseOrder.purchaseDetails.sizeQtyDetails.reduce(
      (acc, item) => +acc + +item.qty,
      0
    );

    console.log('Total qty: ', totalQty);
    setPurchaseOrder((prevState) => {
      const updatedSizeQty = [...prevState.purchaseDetails.sizeQtyDetails];
      updatedSizeQty[index] = {
        ...updatedSizeQty[index],
        [name]: value,
      };
      return {
        ...prevState,
        purchaseDetails: {
          ...prevState.purchaseDetails,
          sizeQtyDetails: updatedSizeQty,
        },
      };
    });
  }

  function handleCloseOrderChange(e, index) {
    if (index === undefined) {
      const { name, value } = e.target;
      setPurchaseOrder((prevState) => ({
        ...prevState,
        closingDetails: {
          ...prevState.closingDetails,
          [name]: value,
        },
      }));
    } else {
      const { name, value } = e.target;
      const totalQty = purchaseOrder.closingDetails.sizeQtyDetails.reduce(
        (acc, item) => +acc + +item.qty,
        0
      );
      setPurchaseOrder((prevState) => {
        const updatedSizeQty = [...prevState.closingDetails.sizeQtyDetails];
        updatedSizeQty[index] = {
          ...updatedSizeQty[index],
          [name]: value,
        };
        return {
          ...prevState,
          closingDetails: {
            ...prevState.closingDetails,
            sizeQtyDetails: updatedSizeQty,
          },
        };
      });
    }
  }

  useEffect(() => {
    const totalQty = purchaseOrder.purchaseDetails.sizeQtyDetails.reduce(
      (acc, item) => +acc + +item.qty,
      0
    );
    console.log('Total Qty: ', totalQty);
    setPurchaseOrder((prevState) => ({
      ...prevState,
      purchaseDetails: {
        ...prevState.purchaseDetails,
        total: totalQty,
      },
    }));
  }, [purchaseOrder.purchaseDetails.sizeQtyDetails]);

  useEffect(() => {
    const totalQty = purchaseOrder.closingDetails.sizeQtyDetails.reduce(
      (acc, item) => +acc + +item.qty,
      0
    );
    console.log('Total Qty: ', totalQty);
    setPurchaseOrder((prevState) => ({
      ...prevState,
      closingDetails: {
        ...prevState.closingDetails,
        total: totalQty,
      },
    }));
  }, [purchaseOrder.closingDetails.sizeQtyDetails]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getProducts();
        setProducts(products);
        const fabricators = await getFabricators();
        setFabricators(fabricators);
        console.log('Products:, ', products);
        console.log('Fabricatorss:, ', fabricators);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
    //Set todays and expected order completion date
    setPurchaseOrder((prevState) => ({
      ...prevState,
      orderDate: new Date().toISOString().split('T')[0],
      expectedCompletionDate: new Date(
        new Date().setDate(new Date().getDate() + 7)
      )
        .toISOString()
        .split('T')[0],
    }));
  }, []);

  useEffect(() => {
    //jaise hi product select hoga, product wale variable se product dhundo
    //or us object se variants me se unique size nikal kar
    //naya object banakar, purchaseOrder ko update kar do
    console.log('M: All products: ', products);
    console.log('M: PurchaseOrder.product: ', purchaseOrder.product);
    const product =
      products &&
      products.find((product) => product.$id === purchaseOrder.product);
    console.log('M: product expected: ', product);
    setProduct(product);
    if (product) {
      const sizeQtyObj = product && getSizeQtyObj(product.variants);
      setPurchaseOrder((prevState) => ({
        ...prevState,
        purchaseDetails: {
          ...prevState.purchaseDetails,
          sizeQtyDetails: sizeQtyObj,
        },
        closingDetails: {
          ...prevState.closingDetails,
          sizeQtyDetails: sizeQtyObj,
        },
      }));
    }
  }, [purchaseOrder.product]);

  function onSubmit(e) {
    e.preventDefault();
    createPurchaseOrder(purchaseOrder);
  }

  function handleUpdate() {
    console.log('Update Purchase order to be done yet');
    updatePurchaseOrder(purchaseOrder.$id, purchaseOrder);
  }

  function handleCloseOrder() {
    // setPurchaseOrder((prevState) => ({
    //   ...prevState,
    //   status: "Closed",
    // }));
    updatePurchaseOrder(purchaseOrder.$id, {
      ...purchaseOrder,
      status: 'Closed',
    });
  }

  return (
    <>
      {!isLoading && (
        <div className="">
          <form className="p-10 bg-white flex flex-wrap" onSubmit={onSubmit}>
            <div className="opening-order lg:w-1/2 md:w-1/2 sm:w-full xs:w-full  p-3">
              <h1 className="block uppercase tracking-wide text-gray-700 text-lg font-bold ">
                Purchase Details
              </h1>
              <hr className="mb-3" />
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 float-right">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="orderDate"
                  >
                    Order Date
                  </label>
                  <input
                    id="orderDate"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="date"
                    name="orderDate"
                    value={purchaseOrder.orderDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="orderDate"
                  >
                    Expected Completion Date
                  </label>
                  <input
                    id="expectedCompletionDate"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="date"
                    name="expectedCompletionDate"
                    value={purchaseOrder.expectedCompletionDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/3 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="product-type"
                  >
                    Product
                  </label>
                  <input
                    id="product"
                    list="products"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    name="product"
                    value={purchaseOrder.product}
                    onChange={(e) => handleChange(e)}
                  />
                  <datalist id="products">
                    {products.map((product, index) => {
                      return (
                        <option key={index} value={product.$id}>
                          {product.productName}
                        </option>
                      );
                    })}
                  </datalist>
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="orderDate"
                  >
                    Common Product Name
                  </label>
                  <input
                    id="commonProductName"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    name="commonProductName"
                    value={purchaseOrder.commonProductName}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="w-full md:w-1/3 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="product-type"
                  >
                    Fabricator
                  </label>
                  <input
                    id="fabricator"
                    list="fabricators"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    name="fabricator"
                    value={purchaseOrder.fabricator}
                    onChange={(e) => handleChange(e)}
                  />
                  <datalist id="fabricators">
                    {fabricators.map((fabricator, index) => {
                      return (
                        <option key={index} value={fabricator.$id}>
                          {fabricator.name}
                        </option>
                      );
                    })}
                  </datalist>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="instructions"
                  >
                    Instructions
                  </label>
                  <textarea
                    id="instructions"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="instructions"
                    rows="8"
                    value={purchaseOrder.instructions}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor=""
                >
                  Qty Details {purchaseOrder.purchaseDetails.total}
                </label>
                <div className="flex-wrap max-h-96 overflow-y-auto -mx-3 mb-6 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
                  {purchaseOrder.purchaseDetails.sizeQtyDetails.map(
                    (entry, index) => (
                      <div
                        className="p-3 flex flex-col items-center"
                        key={index}
                      >
                        <p className="border border-black rounded-tl rounded-tr">
                          <input
                            className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-20"
                            type="text"
                            name="size"
                            value={entry.size}
                            onChange={(e) => handlePurchaseQtyChange(e, index)}
                          />
                        </p>
                        <p className="border border-black rounded-bl rounded-br">
                          <input
                            className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-20"
                            type="number"
                            name="qty"
                            value={entry.qty}
                            onChange={(e) => handlePurchaseQtyChange(e, index)}
                          />
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                {product?.images &&
                  product.images.map((image, index) => (
                    <div
                      key={index}
                      className="rounded-xl w-20 h-20 md:ml-3 object-cover"
                    >
                      <div className="relative bg-white rounded-lg shadow-md overflow-hidden">
                        <img className="" src={image} alt="productImage" />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="flex items-center justify-center">
                {purchaseOrderData.$id ? (
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
            <div className="closing-order lg:w-1/2 md:w-1/2 sm:w-full xs:w-full  p-3">
              <h1
                className="block uppercase tracking-wide text-gray-700 text-lg font-bold "
              >
                Closing Details
              </h1>
              <hr className="mb-3" />
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full lg:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="orderDate"
                  >
                    Actual Completion Date
                  </label>
                  <input
                    id="actualCompletionDate"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="date"
                    name="closingDate"
                    value={purchaseOrder.closingDetails.closingDate}
                    onChange={(e) => handleCloseOrderChange(e, undefined)}
                  />
                </div>
                <div className="w-full lg:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="orderDate"
                  >
                    Order Status
                  </label>
                  {/* <input
                    id="orderStatus"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="date"
                    name="status"
                    value={purchaseOrder.closingDetails.closingDate}
                    onChange={(e) => handleCloseOrderChange(e, undefined)}
                  /> */}
                  <select
                    name="status"
                    id="status"
                    value={purchaseOrder.status}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="Logged">Logged</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Blocked">Blocked</option>
                    <option value="Closed">Closed</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="instructions"
                  >
                    Closing Remarks
                  </label>
                  <textarea
                    id="closingRemarks"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="closingRemarks"
                    rows="8"
                    value={purchaseOrder.closingDetails.closingRemarks}
                    onChange={(e) => handleCloseOrderChange(e, undefined)}
                  />
                </div>
              </div>
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor=""
                >
                  Qty Details {purchaseOrder.closingDetails.total}
                </label>
                <div className="flex-wrap max-h-96 overflow-y-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
                  {purchaseOrder.closingDetails.sizeQtyDetails.map(
                    (entry, index) => (
                      <div
                        className="p-3 flex flex-col items-center"
                        key={index}
                      >
                        <p className="border border-black rounded-tl rounded-tr ">
                          <input
                            className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-20"
                            type="text"
                            name="size"
                            value={entry.size}
                            onChange={(e) => handleCloseOrderChange(e, index)}
                          />
                        </p>
                        <p className="border border-black rounded-bl rounded-br">
                          <input
                            className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-20"
                            type="number"
                            name="qty"
                            value={entry.qty}
                            onChange={(e) => handleCloseOrderChange(e, index)}
                          />
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center">
                {purchaseOrderData.$id && (
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleCloseOrder}
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
            {/* <pre>{JSON.stringify(purchaseOrder, null, 2)}</pre> */}
          </form>
        </div>
      )}
    </>
  );
};

export default CreatePurchaseOrderForm;
