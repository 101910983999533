import React, { useState } from "react";
import {
  createFabricator,
  createType,
  updateFabricator,
} from "../../lib/appwrite/api";
import { toast } from "react-toastify";

const CreateFabricatorForm = (props) => {
  const { fabricatorData } = props;
  // const [fabricator, setFabricator] = useState({
  //   name: "",
  //   contact: "",
  //   address: "",
  // });

  const [fabricator, setFabricator] = useState(fabricatorData);
  console.log("M: fabricatorData", fabricatorData);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createFabricator(fabricator);

      if (response.$id) {
        toast.success("Fabricator created successfully");
        //update local state also.
        /*This needs to be handled while using redux store.*/
      } else {
        console.log("Creation Failed");
      }
    } catch (error) {
      console.error("Error creating fabricator:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFabricator((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFabricatorUpdate = async () => {
    // fabricatorData
    console.log("sk: You need to add api to update Type");
    const fabricatorId = fabricatorData.$id;

    try {
      const response = await updateFabricator(fabricator, fabricatorId);

      if (response.$id) {
        toast.success("Fabricator Updated!");
        //update local state also.
        /*This needs to be handled while using redux store.*/
      } else {
        console.log("Updation Failed");
      }
    } catch (error) {
      console.error("Error updating fabricator:", error);
    }
  };

  return (
    <>
      <form className="p-10 bg-white" onSubmit={onSubmit}>
        {/* Div for name, type, category */}
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="fabricator-name"
            >
              Fabricator Name
            </label>
            <input
              id="fabricator-name"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="name"
              value={fabricator.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="fabricator-contact-number"
            >
              Fabricator Contact Number
            </label>
            <input
              id="fabricator-contact-number"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="contact"
              value={fabricator.contact}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="fabricator-address"
            >
              Fabricator Address
            </label>
            <textarea
              id="fabricator-address"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="address"
              value={fabricator.address}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="flex items-center justify-center">
          {fabricator.$id ? (
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleFabricatorUpdate}
            >
              Update
            </button>
          ) : (
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Submit
            </button>
          )}
        </div>
        {/* <pre>{JSON.stringify(fabricator, null, 2)}</pre> */}
      </form>
    </>
  );
};

export default CreateFabricatorForm;
//
