import { MdDelete, MdModeEdit } from "react-icons/md";

const colorPallete = [
    "#E74C3C",
    "#8E44AD",
    "#F39C12",
    "#3498DB",
    "#1ABC9C",
    "#28B463",
    "#F1C40F",
];

const productDetailsTemplate = (props) => {
    const colors = colorPallete.length;
    const randomNum = Math.floor(Math.random() * colors);

    return (
        <div className="flex flex-wrap">
            {props.products.map((product, index) => {
                return (
                    <span
                        key={index}
                        style={{
                            background: colorPallete[(randomNum + index) % colors],
                        }}
                        className="text-white py-1 px-2 capitalize rounded-2xl text-xs m-1"
                    >
                        {product.productName}
                    </span>
                );
            })}
        </div>
    );
};

const actionsTemplate = (props) => {
    console.log("M: props expecting a function too: ", props);
    return (
        <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
            <p className="flex items-center gap-2 text-gray-700 hover:drop-shadow-xl text-xl cursor-pointer">
                <span>
                    <MdModeEdit onClick={() => props.column.onEditFabricator(props.$id)} />
                    {/* <MdModeEdit onClick={() => console.log("Edit button clicked")} /> */}
                </span>
            </p>
            <p className="flex items-center gap-2 text-red-400 hover:drop-shadow-xl text-xl cursor-pointer">
                <span>
                    <MdDelete onClick={() => props.column.onDeleteFabricator(props.$id)} />
                    {/* <MdDelete onClick={() => console.log("Delete button clicked")} /> */}
                </span>
            </p>
        </div>
    );
};



export const fabricatorsGrid = [
    {
        field: "name",
        headerText: "Fabricator",
        width: "100",
        textAlign: "Left",
    },
    {
        field: "contact",
        headerText: "Contact",
        textAlign: "Center",
        width: "200",
    },
    {
        field: "action",
        headerText: "Action",
        width: "150",
        textAlign: "Center",
        template: actionsTemplate,
    }
];
