import React from "react";
import image from "../assets/infinityLoader.svg"; // Replace "image.jpg" with the actual filename and path

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-[80vh]">
      {/* Using h-screen to make it full screen and flex classes to center the content */}
      <img className="mb-50" src={image} alt="Loader" />
    </div>
  );
};

export default Loader;
