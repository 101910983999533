import { ID, Query } from "appwrite";
import { account, appwriteConfig, avatars, databases, storage } from "./config";
import {
  extractImageId,
  parseProductFromApiResponse,
} from "../../utils/products";
import { parsePurchaseOrderFromApiResponse } from "../../utils/purchaseOrders";

export async function uploadFile(file) {
  console.log("The type of uploaded obj is:", typeof file);
  try {
    const uploadedFile = await storage.createFile(
      appwriteConfig.storageId,
      ID.unique(),
      file
    );
    return uploadedFile;
  } catch (error) {
    console.log(error);
  }
}

export function getFilePreview(fileId) {
  try {
    const fileUrl = storage.getFilePreview(
      appwriteConfig.storageId,
      fileId,
      2000,
      2000,
      "top",
      100
    );

    return fileUrl;
  } catch (error) {
    console.log(error);
  }
}

// ============================== DELETE FILE
export async function deleteFile(fileId) {
  try {
    await storage.deleteFile(appwriteConfig.storageId, fileId);

    return { status: "ok" };
  } catch (error) {
    console.log(error);
  }
}

/** CRUD FOR PRODUCT STARTS **/
export async function createProduct(product, files) {
  try {
    const uploadedFileUrls = [];

    // Iterate over each file in the files array
    if (files)
      for (let file of files) {
        const uploadedFile = await uploadFile(file);
        if (!uploadedFile) throw new Error("Failed to upload file");

        // Get File Url
        const fileUrl = getFilePreview(uploadedFile.$id);
        if (!fileUrl) {
          deleteFile(uploadedFile.$id);
          throw new Error("Failed to get file preview URL");
        }

        // Store the file URL or ID
        uploadedFileUrls.push(fileUrl); // or uploadedFile.$id depending on your requirement
      }

    const newProduct = await databases.createDocument(
      appwriteConfig.databaseId,
      appwriteConfig.productsCollectionId,
      ID.unique(),
      {
        productName: product.productName,
        type: JSON.stringify(product.type),
        images: JSON.stringify(uploadedFileUrls),
        categories: product.categories,
        discount: product.discount,
        description: product.description,
        variants: JSON.stringify(product.variants),
        inStore: product.inStore,
      }
    );

    if (!newProduct) {
      throw Error;
    }
    return newProduct;
  } catch (error) {
    console.log(error);
  }
}

//Get product by ID
export async function getProductById(productId) {
  try {
    const product = await databases.getDocument(
      appwriteConfig.databaseId,
      appwriteConfig.productsCollectionId,
      productId
    );

    if (!product) {
      console.error(`Product with ID ${productId} not found`);
      throw new Error("Product not found");
    }

    console.log(`API response for product with ID: ${productId}`, product);
    console.log("parsedProduct: ", parseProductFromApiResponse(product));
    return parseProductFromApiResponse(product);
  } catch (error) {
    // console.error("Error fetching or parsing product:", error);
    throw error; // Rethrow the error for the calling code to handle as needed
  }
}

//To list All products
// This function is set to fetch 500 products, in future there might be a need to increase this limit
export async function getProducts() {
  const products = await databases.listDocuments(
    appwriteConfig.databaseId,
    appwriteConfig.productsCollectionId,
    [Query.limit(500)]
  );

  if (!products) throw Error;
  console.log("Before parsing:", products);
  //console.log("After parsing:",JSON.parse(types[0]));
  const parsedProducts = products.documents.map((product) => {
    return {
      $id: product.$id,
      productName: product.productName,
      type: JSON.parse(product.type),
      discount: product.discount,
      description: product.description,
      inStore: product.inStore,
      images: JSON.parse(product.images),
      categories: product.categories,
      variants: JSON.parse(product.variants),
    };
  });

  //console.log("After parsing:",parsedTypes);
  return parsedProducts;
}

//Update a product
export async function updateProduct(product, files, productId) {
  try {
    const uploadedFileUrls = [];

    // Iterate over each file in the files array
    if (files)
      for (let file of files) {
        const uploadedFile = await uploadFile(file);
        if (!uploadedFile) throw new Error("Failed to upload file");

        // Get File Url
        const fileUrl = getFilePreview(uploadedFile.$id);
        if (!fileUrl) {
          deleteFile(uploadedFile.$id);
          throw new Error("Failed to get file preview URL");
        }
        // Store the file URL or ID
        uploadedFileUrls.push(fileUrl); // or uploadedFile.$id depending on your requirement
      }

    const updatedProduct = await databases.updateDocument(
      appwriteConfig.databaseId,
      appwriteConfig.productsCollectionId,
      productId,
      {
        productName: product.productName,
        type: JSON.stringify(product.type),
        //images: JSON.stringify([...product.images, ...uploadedFileUrls]),
        images: JSON.stringify(
          [...product.images, ...uploadedFileUrls].filter(
            (imageUrl) =>
              !(typeof imageUrl === "string" && imageUrl.startsWith("blob"))
            //(imageUrl) => console.log(typeof imageUrl, imageUrl)
          )
        ),
        categories: product.categories,
        discount: product.discount,
        description: product.description,
        variants: JSON.stringify(product.variants),
        inStore: product.inStore,
      }
    );
    if (!updatedProduct) {
      throw Error;
    }
    return updatedProduct;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteProduct(productId, product) {
  const imageIds = (product.images).map((url) => extractImageId(url));

  try {
    // for (let image of imageIds) {
    //   console.log("Deleting: ", image);
    //   if (image) deleteFile(image);
    // }
    console.log("Mayank: Trying to delete: ", productId, product);
    const statusCode = await databases.deleteDocument(
      appwriteConfig.databaseId,
      appwriteConfig.productsCollectionId,
      product.$id
    );
    if (!statusCode) throw Error;
    return { status: "ok" };
  } catch (error) {
    console.log(error);
  }
}

/** CRUD FOR PRODUCT ENDS **/

/** CRUD FOR TYPE STARTS **/
//This function creates product types that autopopulates the variants.
export async function createType(type) {
  try {
    const newType = await databases.createDocument(
      appwriteConfig.databaseId,
      appwriteConfig.productTypesCollectionId,
      ID.unique(),
      {
        name: type.name,
        variations: JSON.stringify(type.variations),
      }
    );

    if (!newType) {
      throw Error;
    }
    return newType;
  } catch (error) {
    console.log(error);
  }
}

export async function getTypeById(typeId) {
  try {
    const type = await databases.getDocument(
      appwriteConfig.databaseId,
      appwriteConfig.productTypesCollectionId,
      typeId
    );

    if (!type) throw Error;
    console.log("M: Before parsing:", type);
    const parsedType = {
      $id: type.$id,
      name: type.name,
      variations: JSON.parse(type.variations),
    };

    return parsedType;
  } catch (error) {
    console.log(error);
  }
}

export async function getType() {
  try {
    const types = await databases.listDocuments(
      appwriteConfig.databaseId,
      appwriteConfig.productTypesCollectionId

    );

    if (!types) throw Error;
    const parsedTypes = types.documents.map((type) => {
      return {
        $id: type.$id,
        name: type.name,
        variations: JSON.parse(type.variations),
      };
    });
    return parsedTypes;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteTypeById(typeId) {
  try {
    const statusCode = await databases.deleteDocument(
      appwriteConfig.databaseId,
      appwriteConfig.productTypesCollectionId,
      typeId
    );
    if (!statusCode) throw Error;
    return { status: "ok" };
  } catch (error) {
    console.log(error);
  }
}

//update product type
export async function updateType(type, typeId) {
  try {
    const updatedType = await databases.updateDocument(
      appwriteConfig.databaseId,
      appwriteConfig.productTypesCollectionId,
      typeId,
      {
        name: type.name,
        variations: JSON.stringify(type.variations),
      }
    );
    if (!updatedType) {
      throw Error;
    }
    return updatedType;
  } catch (error) {
    console.log(error);
  }
}

/** CRUD FOR TYPE ENDS **/

/** CRUD FOR PRODUCT CATEGORY STARTS */
//create Product Category
export async function createProductCategory(category) {
  console.log("Trying to submit", category);
  try {
    const newCategory = await databases.createDocument(
      appwriteConfig.databaseId,
      appwriteConfig.categoriesCollectionId,
      ID.unique(),
      {
        name: category.name,
        products: category.products.map((product) => {
          return product.$id;
        }),
      }
    );

    if (!newCategory) {
      throw Error;
    }
    return newCategory;
  } catch (error) {
    console.log(error);
  }
}

//get product categories
//To list All products
export async function getProductCategories() {
  try {
    const productCategories = await databases.listDocuments(
      appwriteConfig.databaseId,
      appwriteConfig.categoriesCollectionId
      //[Query.orderDesc('$createdAt'),Query.limit(20)]
    );

    if (!productCategories) throw Error;
    console.log("Before parsing:", productCategories);
    //console.log("After parsing:",JSON.parse(types[0]));
    const parsedCategories = productCategories.documents.map((category) => {
      return category;
    });
    return parsedCategories;
  } catch (error) {
    console.log(error);
  }
}

//get product category By Id
export async function getProductCategoryById(categoryId) {
  try {
    const productCategory = await databases.getDocument(
      appwriteConfig.databaseId,
      appwriteConfig.categoriesCollectionId,
      categoryId
    );

    if (!productCategory) throw Error;
    const parsedCategory = {
      $id: productCategory.$id,
      name: productCategory.name,
      products: productCategory.products.map((product) => product.$id),
    };
    return parsedCategory;
  } catch (error) {
    console.log(error);
  }
}

//update product type
export async function updateCategory(categoryId, category) {
  console.log("new Data is:", category);
  try {
    const updatedCategory = await databases.updateDocument(
      appwriteConfig.databaseId,
      appwriteConfig.categoriesCollectionId,
      categoryId,
      {
        name: category.name,
        products: category.products,
      }
    );
    if (!updatedCategory) {
      throw Error;
    }
    return updatedCategory;
  } catch (error) {
    console.log(error);
  }
}

//deleteCategory
export async function deleteCategoryById(categoryId) {
  try {
    const statusCode = await databases.deleteDocument(
      appwriteConfig.databaseId,
      appwriteConfig.categoriesCollectionId,
      categoryId
    );
    if (!statusCode) throw Error;
    return { status: "ok" };
  } catch (error) {
    console.log(error);
  }
}

/** CRUD FOR PRODUCT CATEGORY STARTS */

/** CRUD FOR PRODUCT FABRICATOR STARTS */
//create fabricator
export async function createFabricator(fabricator) {
  try {
    const newFabricator = await databases.createDocument(
      appwriteConfig.databaseId,
      appwriteConfig.fabricatorsCollectionId,
      ID.unique(),
      {
        name: fabricator.name,
        contact: fabricator.contact,
        address: fabricator.address,
      }
    );

    if (!newFabricator) {
      throw Error;
    }
    return newFabricator;
  } catch (error) {
    console.log(error);
  }
}

//To list All Fabricators
export async function getFabricators() {
  try {
    const fabricators = await databases.listDocuments(
      appwriteConfig.databaseId,
      appwriteConfig.fabricatorsCollectionId
    );

    if (!fabricators) throw Error;
    const parsedFabricators = fabricators.documents.map((fabricator) => {
      return {
        $id: fabricator.$id,
        name: fabricator.name,
        contact: fabricator.contact,
        address: fabricator.address,
      };
    });
    return parsedFabricators;
  } catch (error) {
    console.log(error);
  }
}

//To get Fabricator By ID
export async function getFabricatorById(fabricatorId) {
  try {
    const fabricator = await databases.getDocument(
      appwriteConfig.databaseId,
      appwriteConfig.fabricatorsCollectionId,
      fabricatorId
    );

    if (!fabricator) throw Error;
    const parsedFabricator = {
      $id: fabricator.$id,
      name: fabricator.name,
      contact: fabricator.contact,
      address: fabricator.address,
      purchaseOrders: fabricator.purchaseOrders.map((purchaseOrder) => {
        return {
          product: purchaseOrder.product,
          commonProductName: purchaseOrder.commonProductName,
          orderDate: purchaseOrder.orderDate,
          expectedCompletionDate: purchaseOrder.expectedCompletionDate,
          instructions: purchaseOrder.instructions,
          purchaseDetails: JSON.parse(purchaseOrder.purchaseDetails),
          closingDetails: JSON.parse(purchaseOrder.closingDetails),
          status: purchaseOrder.status,
        };
      }),
    };
    return parsedFabricator;
  } catch (error) {
    console.log(error);
  }
}

//delete Fabricator
export async function deleteFabricatorById(fabricatorId) {
  try {
    const statusCode = await databases.deleteDocument(
      appwriteConfig.databaseId,
      appwriteConfig.fabricatorsCollectionId,
      fabricatorId
    );

    if (!statusCode) {
      throw new Error("Failed to delete fabricator. Status code is missing.");
    }

    return { status: "ok" };
  } catch (error) {
    console.error("Error deleting fabricator:", error.message);
    return { status: "error", message: error.message };
  }
}

// Update fabricator
export async function updateFabricator(fabricator, fabricatorId) {
  try {
    const updatedFabricator = await databases.updateDocument(
      appwriteConfig.databaseId,
      appwriteConfig.fabricatorsCollectionId,
      fabricatorId,
      {
        name: fabricator.name,
        contact: fabricator.contact,
        address: fabricator.address,
      }
    );
    if (!updatedFabricator) {
      throw Error;
    }
    return updatedFabricator;
  } catch (error) {
    console.log(error);
  }
}

/** CRUD FOR FABRICATOR ENDS */

/** CRUD FOR PURCHASE ORDER STARTS */
//Create Purchase Order
export async function createPurchaseOrder(purchaseOrder) {
  console.log("Trying to submit", purchaseOrder);
  try {
    const newPurchaseOrder = await databases.createDocument(
      appwriteConfig.databaseId,
      appwriteConfig.purchaseOrdersCollectionId,
      ID.unique(),
      {
        product: purchaseOrder.product,
        commonProductName: purchaseOrder.commonProductName,
        instructions: purchaseOrder.instructions,
        fabricator: purchaseOrder.fabricator,
        purchaseDetails: JSON.stringify(purchaseOrder.purchaseDetails),
        orderDate: purchaseOrder.orderDate,
        expectedCompletionDate: purchaseOrder.expectedCompletionDate,
        closingDetails: JSON.stringify(purchaseOrder.closingDetails),
        status: purchaseOrder.status,
      }
    );

    if (!newPurchaseOrder) {
      throw Error;
    }
    return newPurchaseOrder;
  } catch (error) {
    console.log(error);
  }
}

export async function updatePurchaseOrder(purchaseOrderId, purchaseOrder) {
  console.log("Trying to update", purchaseOrder);
  try {
    const updatedPurchaseOrder = await databases.updateDocument(
      appwriteConfig.databaseId,
      appwriteConfig.purchaseOrdersCollectionId,
      purchaseOrderId,
      {
        product: purchaseOrder.product,
        commonProductName: purchaseOrder.commonProductName,
        instructions: purchaseOrder.instructions,
        fabricator: purchaseOrder.fabricator,
        purchaseDetails: JSON.stringify(purchaseOrder.purchaseDetails),
        orderDate: purchaseOrder.orderDate,
        expectedCompletionDate: purchaseOrder.expectedCompletionDate,
        closingDetails: JSON.stringify(purchaseOrder.closingDetails),
        status: purchaseOrder.status,
      }
    );

    if (!updatedPurchaseOrder) {
      throw Error;
    }
    return updatedPurchaseOrder;
  } catch (error) {
    console.log(error);
  }
}

//To list All purchase Orders
//This will fetch 5000 orders, in future it must be increased
export async function getPurchaseOrders() {
  try {
    const purchaseOrders = await databases.listDocuments(
      appwriteConfig.databaseId,
      appwriteConfig.purchaseOrdersCollectionId,
      [Query.limit(5000)]
    );

    if (!purchaseOrders) throw Error;
    console.log("purchaseOrders Before parsing:", purchaseOrders);
    const parsedPurchaseOrders = purchaseOrders.documents.map(
      (purchaseOrder) => {
        return {
          $id: purchaseOrder.$id,
          product: purchaseOrder.product,
          commonProductName: purchaseOrder.commonProductName,
          instructions: purchaseOrder.instructions,
          fabricator: purchaseOrder.fabricator,
          purchaseDetails: JSON.parse(purchaseOrder.purchaseDetails),
          orderDate: purchaseOrder.orderDate,
          expectedCompletionDate: purchaseOrder.expectedCompletionDate,
          closingDetails: JSON.parse(purchaseOrder.closingDetails),
          status: purchaseOrder.status,
        };
      }
    );
    return parsedPurchaseOrders;
  } catch (error) {
    console.log(error);
  }
}

//get purchase order by Id
export async function getPurchaseOrderById(purchaseOrderId) {
  try {
    const purchaseOrder = await databases.getDocument(
      appwriteConfig.databaseId,
      appwriteConfig.purchaseOrdersCollectionId,
      purchaseOrderId
    );

    if (!purchaseOrder) {
      console.error(`Purchase order with ID ${purchaseOrderId} not found`);
      throw new Error("PurchaseOrder not found");
    }

    console.log(
      `API response for purchaseOrder with ID: ${purchaseOrderId}`,
      purchaseOrder
    );
    console.log(
      "parsedPurchaseOrder: ",
      parsePurchaseOrderFromApiResponse(purchaseOrder)
    );
    return parsePurchaseOrderFromApiResponse(purchaseOrder);
  } catch (error) {
    console.error("Error fetching or parsing purchaseOrder:", error);
    throw error; // Rethrow the error for the calling code to handle as needed
  }
}

//delete purchase order
export async function deletePurchaseOrderById(purchaseOrderId) {
  try {
    const statusCode = await databases.deleteDocument(
      appwriteConfig.databaseId,
      appwriteConfig.purchaseOrdersCollectionId,
      purchaseOrderId
    );
    if (!statusCode) throw Error;
    return { status: "ok" };
  } catch (error) {
    console.log(error);
  }
}

/** CRUD FOR PURCHASE ORDER ENDS */
