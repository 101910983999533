import React, { useEffect, useState } from 'react';
import { fabricatorFormDataTemplate } from '../../dtos/dtos';
import Modal from '../../components/SSUComponents/Modal';
import CreateFabricatorForm from '../../forms/products/CreateFabricatorForm';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from '@syncfusion/ej2-react-grids';
import { fabricatorsGrid } from '../../constants/fabricators';
import {
  deleteFabricatorById,
  getFabricatorById,
  getFabricators,
} from '../../lib/appwrite/api';
import { toast } from 'react-toastify';
import RButton from '../../components/RButton';

const Fabricators = () => {
  const [fabricatorData, setFabricatorData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allFabricatorData, setAllFabricatorData] = useState();
  console.log('sk:🚀 ~ Fabricators ~ allFabricatorData:', allFabricatorData);
  const editing = {
    allowDeleting: true,
    allowEditing: true,
  };
  const toolbarOptions = ['Search'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fabricatorData = await getFabricators();
        setAllFabricatorData(fabricatorData);
        setIsLoading(false);
      } catch (error) {
        console.error('sk: Error fetching product categories:', error);
      }
    };
    fetchData();
  }, []);

  const openCreateFabricator = () => {
    setFabricatorData(fabricatorFormDataTemplate);
    setShowModal(true);
  };

  let grid;
  const created = () => {
    document
      .getElementById(grid.element.id + '_searchbar')
      .addEventListener('keyup', () => {
        grid.search(event.target.value);
      });
  };

  const onEditFabricator = (fabricatorId) => {
    const fetchData = async () => {
      try {
        const fabricatorData = await getFabricatorById(fabricatorId);
        setFabricatorData(fabricatorData);
        setShowModal(true);
      } catch (error) {
        console.error('sk: Error fetching category:', error);
      }
    };
    if (fabricatorId) fetchData();
  };

  const onDeleteFabricator = async (fabricatorId) => {
    try {
      const response = await deleteFabricatorById(fabricatorId);

      if (response.status === 'ok') {
        setAllFabricatorData((prevAllFabricators) =>
          prevAllFabricators.filter(
            (fabricator) => fabricator.$id !== fabricatorId
          )
        );
        toast.success('Deleted Successfully!');
      } else {
        console.log('Deletion failed');
      }
    } catch (error) {
      console.error('Error deleting type:', error);
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-end">
          <RButton onClick={openCreateFabricator}>
            Create New Fabricator
          </RButton>
        </div>

        {showModal && (
          <>
            <Modal setShowModal={setShowModal} title="Create Fabricator">
              {/* <CreateFabricatorForm productCategory={productCategoryData} /> */}
              <CreateFabricatorForm fabricatorData={fabricatorData} />
            </Modal>
          </>
        )}

        {!isLoading && (
          <div>
            <GridComponent
              id="gridcomp"
              dataSource={allFabricatorData}
              width="auto"
              allowPaging
              allowSorting
              pageSettings={{ pageCount: 5 }}
              editSettings={editing}
              toolbar={toolbarOptions}
              ref={(g) => (grid = g)}
              created={created}
            >
              <ColumnsDirective>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {fabricatorsGrid.map((item, index) =>
                  item.field === 'action' ? (
                    <ColumnDirective
                      key={index}
                      {...item}
                      onEditFabricator={onEditFabricator}
                      onDeleteFabricator={onDeleteFabricator}
                    />
                  ) : (
                    <ColumnDirective key={index} {...item} />
                  )
                )}
              </ColumnsDirective>
              <Inject services={[Search, Page]} />
            </GridComponent>
          </div>
        )}
      </div>
    </>
  );
};

export default Fabricators;
