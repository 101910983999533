import React, { useState, useEffect } from "react";
import {
  createProductCategory,
  getProducts,
  updateCategory,
} from "../../lib/appwrite/api";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { toast } from "react-toastify";

const CreateProductCategoryForm = (props) => {
  const { productCategory } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState();
  const [formData, setFormData] = useState(productCategory);

  function handleChange(e) {
    const { name, value } = e.target;
    console.log("value changed", value);

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const onSubmit = async (e) => {
    //extract Id from productName & send createCategory request
    e.preventDefault();
    const selectedProducts = products.filter((product) =>
      formData.products.includes(product.$id)
    );

    try {
      const response = await createProductCategory({ name: formData.name, products: selectedProducts });

      if (response.$id) {
        toast.success("Category Created successfully")
        //update local state also.
        /*This needs to be handled while using redux store.*/
      } else {
        console.log("Creation Failed");
      }
    } catch (error) {
      console.error("Error creating category:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getProducts();
        setProducts(products);
        console.log(
          "Fetched products in CreateProductCategoryForm: ",
          products
        );
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        // Optionally handle the error as needed, e.g., set a default state or show a message.
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async () => {
    console.log("You need to add api to update Type");
    const categoryId = productCategory.$id;

    try {
      const response = await updateCategory(categoryId, formData);

      if (response.$id) {
        toast.success("Category Updated!");
        //update local state also.
        /*This needs to be handled while using redux store.*/
      } else {
        console.log("Updation Failed");
      }
    } catch (error) {
      console.error("Error updatingCategory:", error);
    }
  };

  // maps the appropriate column to fields property, used by MultiSelect
  const fields = { text: "productName", value: "$id" };

  return (
    <>
      {!isLoading && (
        <form className="p-10 bg-white" onSubmit={onSubmit}>
          {/* Div for name, type, category */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="product-type"
              >
                Category Name
              </label>
              <input
                id="product-type"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div>
            <MultiSelectComponent
              id="checkbox"
              dataSource={products}
              fields={fields}
              placeholder="Select Products"
              mode="CheckBox"
              name="products"
              value={formData.products}
              onChange={(e) => handleChange(e)}
            >
              <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
          </div>

          <div className="flex items-center justify-center">
            {productCategory.$id ? (
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleUpdate}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
              >
                Submit
              </button>
            )}
          </div>
          <pre>{JSON.stringify(formData, null, 2)}</pre>
        </form>
      )}
    </>
  );
};

export default CreateProductCategoryForm;
