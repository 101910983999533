import React, { useState, useEffect } from 'react';
import {
  createProduct,
  deleteFile,
  getProductCategories,
  getType,
  updateProduct,
} from '../../lib/appwrite/api';
import {
  extractImageId,
  generateProductsArray,
  generateVariants,
} from '../../utils/products';
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from '@syncfusion/ej2-react-dropdowns';
import { toast } from 'react-toastify';
import Switcher from '../../components/Switcher';
import RUpload from '../../components/RUpload';

const CreateProductForm = ({ product }) => {
  console.log('received product prop', product);
  const [productTypes, setProductTypes] = useState();
  const [productCategories, setProductCategories] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(product);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const typesData = await getType();
        const productCategories = await getProductCategories();
        setProductCategories(productCategories);
        console.log('Product Categories:, ', productCategories);
        setProductTypes(typesData);
        console.log('Fetched typesData: ', typesData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching product types:', error);
        // Optionally handle the error as needed, e.g., set a default state or show a message.
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFormData(product);
  }, [product]);

  const [files, setFiles] = useState([]);

  const handleChange = (e, path) => {
    const { name, value } = e.target;
    if (!name) name = e.target.htmlattributes.name;
    if (path) {
      setFormData((prevState) => ({
        ...prevState,
        [path]: {
          ...prevState[path],
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleToggle = (e) => {
    console.log('M: toggling: ', e);
    setFormData((prevState) => ({
      ...prevState,
      inStore: !prevState.inStore,
    }));
  };

  const handleTypeChange = (e) => {
    const { value } = e.target;
    const selectedType = productTypes.find(
      (type) => type.name === e.target.value
    );
    console.log('Selected type is: ', selectedType, e.target.value);
    if (selectedType) {
      setFormData((prevState) => ({
        ...prevState,
        type: selectedType,
      }));
      console.log('Executed');
    } else {
      setFormData((prevState) => ({
        ...prevState,
        type: {
          ...prevState.type,
          name: value,
        },
      }));
    }
  };

  const handleVariationChange = (e, index) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      const updatedVariations = [...prevState.type.variations];
      updatedVariations[index] = {
        ...updatedVariations[index],
        [name]:
          name === 'options'
            ? value.split(',').map((option) => option.trim())
            : value,
      };
      return {
        ...prevState,
        type: {
          ...prevState.type,
          variations: updatedVariations,
        },
      };
    });
  };

  const handleVariantChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value, index);

    setFormData((prevState) => {
      const updatedVariants = [...prevState.variants];
      updatedVariants[index] = {
        ...updatedVariants[index],
        [name]: value,
      };
      return {
        ...prevState,
        variants: updatedVariants,
      };
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    console.log('Type of selectedFiles: ', selectedFiles);
    setFiles([...files, ...selectedFiles]);
    const selectedPreviews = selectedFiles.map((file) =>
      URL.createObjectURL(file)
    );
    setFormData((prevState) => ({
      ...prevState,
      images: [...prevState.images, ...selectedPreviews],
    }));
  };

  const handleDeleteImage = (indexToRemove) => {
    if (product.$id) {
      deleteFile(extractImageId(formData.images[indexToRemove]));

      setFormData((prevState) => {
        const updatedImages = prevState.images.filter(
          (_, index) => index !== indexToRemove
        );
        // Update formData within the callback
        const updatedFormData = {
          ...prevState,
          images: updatedImages,
        };

        updateProduct(updatedFormData, files, formData.$id);

        return updatedFormData; // Return the updated state
      });
    } else {
      const updatedImages = formData.images.filter(
        (_, index) => index !== indexToRemove
      );

      setFormData((prevState) => ({
        ...prevState,
        images: updatedImages,
      }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createProduct(formData, files);

      if (response.$id) {
        toast.success('Product created successfully');
        //update local state also.
        /*This needs to be handled while using redux store.*/
      } else {
        console.log('Creation Failed');
      }
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };

  async function handleCSVUpload(e) {
    try {
      const productsArray = await generateProductsArray(
        e,
        productCategories,
        productTypes
      );
      console.log('This has been returned', productsArray[0].productName);
      console.log(productsArray[0]);

      // Uncomment the following code to create products after reading
      for (let product of productsArray) {
        try {
          console.log('trying to create product');
          await createProduct(product, undefined);
          console.log('Completed the product');
        } catch (error) {
          console.error('Error creating product:', error);
        }
      }
      console.log('Reaching here');
    } catch (error) {
      console.error('Error generating products array:', error);
    }
  }
  useEffect(() => {
    // Populate variants whenever type.variations changes
    const newVariants = generateVariants(formData.type.variations);
    setFormData((prevState) => ({
      ...prevState,
      variants: newVariants,
    }));
  }, [formData.type.variations, product]);

  //handles update
  const handleUpdate = async () => {
    console.log('You need to add api to update Type');
    const productId = product.$id;
    try {
      const response = await updateProduct(formData, files, productId);

      if (response.$id) {
        toast.success('Product updated');
        //update local state also.
        /*This needs to be handled while using redux store.*/
      } else {
        console.log('Updation Failed');
      }
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  // maps the appropriate column to fields property, used by MultiSelect
  const fields = { text: 'name', value: '$id' };

  return (
    !isLoading && (
      <>
        <div className="flex flex-wrap">
          <div className="mb-3 ml-auto">
            <RUpload text={'Bulk Upload'}>
              <input
                id="uploadFile1"
                type="file"
                onChange={(e) => handleCSVUpload(e)}
                accept=".csv"
                className="hidden"
              />
            </RUpload>
          </div>
          <div className="w-full ">
            <form onSubmit={onSubmit}>
              {/* Div for name, type, category */}
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="product-name"
                  >
                    Product Name
                  </label>
                  <input
                    id="product-name"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    name="productName"
                    value={formData.productName}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="product-type"
                  >
                    Type
                  </label>
                  <input
                    id="product-type"
                    list="product-types"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    name="name"
                    value={formData.type.name}
                    onChange={(e) => handleTypeChange(e)}
                  />
                  <datalist id="product-types">
                    {productTypes.map((option, index) => {
                      return (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      );
                    })}
                  </datalist>
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="product-category"
                  >
                    Category
                  </label>

                  <MultiSelectComponent
                    id="checkbox"
                    dataSource={productCategories}
                    fields={fields}
                    placeholder="Select game"
                    mode="CheckBox"
                    name="categories"
                    value={formData.categories}
                    onChange={(e) => handleChange(e)}
                  >
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </div>

                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="discount"
                  >
                    Discount
                  </label>
                  <input
                    id="discount"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="number"
                    name="discount"
                    value={formData.discount}
                    onChange={(e) => handleChange(e)}
                    min={0}
                    max={10}
                  />
                </div>

                <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0 ">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="discount"
                  >
                    In Store
                  </label>
                  <div className="py-2">
                    <Switcher
                      onChange={handleToggle}
                      currentState={product.inStore}
                      title={'in Store'}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="description"
                  >
                    Description
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="description"
                    value={formData.description}
                    onChange={(e) => handleChange(e)}
                    rows="8"
                  />
                </div>
              </div>

              <div>
                {formData.type.variations.map((variation, index) => (
                  <div key={index} className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Variation Name:
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="name"
                        value={variation.name}
                        onChange={(e) => handleVariationChange(e, index)}
                      />
                    </div>

                    {/* Correction: Use the variation.options for the input value */}
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Variation Options:
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="options"
                        value={variation.options.join(', ')} // Convert array to string for display
                        onChange={(e) => handleVariationChange(e, index)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              {/* This will be variants handling  */}
              <div className="max-h-96 overflow-y-auto">
                {formData.variants.map((variant, index) => (
                  <>
                    <div key={index} className="flex flex-wrap -mx-3 mb-2">
                      <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
                        {/* <p>Sleeve | 12</p> */}
                        <p>
                          {variant[formData.type.variations[0].name]}
                          {' | '}
                          {variant[formData.type.variations[1].name]}
                        </p>
                      </div>
                      <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
                        <label>Cost Price</label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          name="cost_price"
                          value={variant.cost_price}
                          onChange={(e) => handleVariantChange(e, index)}
                        />
                      </div>

                      {/* Correction: Use the variation.options for the input value */}
                      <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
                        <label>MRP </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          name="mrp"
                          value={variant.mrp}
                          onChange={(e) => handleVariantChange(e, index)}
                        />
                      </div>

                      <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
                        <label>Sale Price</label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          name="selling_price"
                          value={variant.selling_price}
                          onChange={(e) => handleVariantChange(e, index)}
                        />
                      </div>
                      <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
                        <label>SKU</label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          name="sku"
                          value={variant.sku}
                          onChange={(e) => handleVariantChange(e, index)}
                        />
                      </div>

                      <div className="w-full md:w-1/6 px-3 mb-6 md:mb-0">
                        <label>Stock</label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          name="stock"
                          value={variant.stock}
                          onChange={(e) => handleVariantChange(e, index)}
                        />
                      </div>
                    </div>
                    <hr className='border-t-1 h-2 border-gray-400'/>
                  </>
                ))}
              </div>

              <div className="flex flex-wrap">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center md:w-1/6  sm:w-1/4 h-44  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Upload</span> Drag
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    multiple
                  />
                </label>

                {formData.images.map((image, index) => (
                  <div
                    key={index}
                    className="w-full md:w-1/6 h-48 p-2 sm:w-1/4"
                  >
                    <div className="relative bg-white rounded-lg shadow-md overflow-hidden w-40 h-40 sm:p-4 md:p-2 lg:p-2">
                      <button
                        type="button"
                        className="absolute top-2 right-2 text-gray-600 hover:text-red-500 focus:outline-none"
                        onClick={() => handleDeleteImage(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                      <img
                        className="w-full h-full object-cover"
                        src={image}
                        alt="productImage"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-center">
                {product.$id ? (
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Submit
                  </button>
                )}
              </div>
              <pre>{JSON.stringify(formData, null, 2)}</pre>
            </form>
          </div>
        </div>
      </>
    )
  );
};

export default CreateProductForm;
