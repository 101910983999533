/**
 * This is a blank template that is bind when creating a product,
 * all form fields when edited, populate data in this object.
 */

export const productFormDataTemplate = {
  productName: "",
  type: {
    name: "",
    variations: [
      {
        name: "",
        options: [],
      },
      {
        name: "",
        options: [],
      },
    ],
  },
  images: [],
  categories: [],
  discount: 10,
  description: "",
  variants: [],
  inStore: false,
};

export const purchaseOrderFormDataTemplate = {
  product: "",
  commonProductName: "",
  instructions: "",
  fabricator: "",
  purchaseDetails: {
    sizeQtyDetails: [
      {
        size: 22,
        qty: 15,
      },
      {
        size: 24,
        qty: 15,
      },
    ],
    total: 11,
  },
  orderDate: "",
  expectedCompletionDate: "",
  closingDetails: {
    closingDate: "",
    sizeQtyDetails: [],
    total: 11,
    primaryFabric: "",
    secondaryFabric: "",
    closingRemarks: "",
  },
  status: "In Queue",
};

export const fabricatorFormDataTemplate = {
  name: "",
  contact: "",
  address: "",
};

export const typeFormDataTemplate = {
  name: "",
  variations: [
    {
      name: "",
      options: [1, 2, 3],
    },
    {
      name: "",
      options: [1, 2, 3],
    },
  ],
};

export const defaultTypeFormDataTemplate = {
  name: "Default",
  variations: [
    {
      name: "",
      options: [],
    },
    {
      name: "",
      options: [],
    },
  ],
};

export const categoryFormDataTemplate = {
  name: "",
  products: [],
};
