import { Client, Account, Databases, Storage, Avatars } from "appwrite";

export const appwriteConfig = {
  url: process.env.REACT_APP_APPWRITE_URL,
  projectId: process.env.REACT_APP_APPWRITE_PROJECT_ID,
  databaseId: process.env.REACT_APP_APPWRITE_DATABASE_ID,
  storageId: process.env.REACT_APP_APPWRITE_STORAGE_ID,
  productTypesCollectionId: process.env.REACT_APP_APPWRITE_PRODUCT_TYPES_COLLECTION_ID,
  saleOrdersCollectionId: process.env.REACT_APP_APPWRITE_SALE_ORDERS_COLLECTION_ID,
  customersCollectionId: process.env.REACT_APP_APPWRITE_CUSTOMERS_COLLECTION_ID,
  purchaseOrdersCollectionId:process.env.REACT_APP_APPWRITE_PURCHASE_ORDERS_COLLECTION_ID,
  categoriesCollectionId:process.env.REACT_APP_APPWRITE_CATEGORIES_COLLECTION_ID,
  fabricCollectionId:process.env.REACT_APP_APPWRITE_FABRIC_COLLECTION_ID,
  fabricatorsCollectionId:process.env.REACT_APP_APPWRITE_FABRICATORS_COLLECTION_ID,
  productsCollectionId:process.env.REACT_APP_APPWRITE_PRODUCTS_COLLECTION_ID
};

export const client = new Client();
client.setProject(appwriteConfig.projectId);
client.setEndpoint(appwriteConfig.url);

export const account = new Account(client);
export const databases = new Databases(client);
export const storage = new Storage(client);
export const avatars = new Avatars(client);