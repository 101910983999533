import React, { useEffect, useState } from 'react'
import CreatePurchaseOrderForm from '../../forms/products/CreatePurchaseOrderForm'
import { useParams } from 'react-router-dom'
import { purchaseOrderFormDataTemplate } from '../../dtos/dtos';
import { getPurchaseOrderById } from '../../lib/appwrite/api';

const CreatePurchaseOrders = () => {
  const {purchaseOrderId} = useParams();
  const [purchaseOrder,setPurchaseOrder] = useState();
  const [isLoading,setIsLoading] = useState(true);

  useEffect(()=>{

    const fetchData = async () => {
      try {
        const purchaseOrder = await getPurchaseOrderById(purchaseOrderId);
        console.log("Purchase order fetched",purchaseOrder)
        setPurchaseOrder(purchaseOrder);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching purchase Order:", error);
        setIsLoading(false);
        // Optionally handle the error as needed, e.g., set a default state or show a message.
      }
    };

    if(purchaseOrderId){
      fetchData();
    }else{
      setPurchaseOrder(purchaseOrderFormDataTemplate);
      setIsLoading(false);
    }
  },[]);


  return (
    !isLoading && <CreatePurchaseOrderForm purchaseOrderData={purchaseOrder}/>
  )
}

export default CreatePurchaseOrders