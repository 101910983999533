//  When product is selected, this function return size-qty object that we are using to populate purchase orders
export const getSizeQtyObj = (variants) => {
  //  use this variants to extract unique size
  //  Step 1: Extract all sizes
  const allSizes = variants.map((item) => item.size);

  // Step 2: Filter out duplicates to get unique sizes
  const uniqueSizes = [...new Set(allSizes)];

  // Step 3: Construct the desired array structure
  const result = uniqueSizes.map((size) => ({
    size,
    qty: 0,
  }));
  return result;
};

//  convert image url to base 64
export async function imageUrlToBase64(imageUrl) {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  } catch (error) {
    console.error('Error fetching the image:', error);
    throw error;
  }
}

//  Given api response, this function filters necessary values and forward it to components.
export const parsePurchaseOrderFromApiResponse = (requestedPurchaseOrder) => {
  try {
    return {
      $id: requestedPurchaseOrder.$id,
      product: requestedPurchaseOrder.product.$id,
      commonProductName: requestedPurchaseOrder.commonProductName,
      instructions: requestedPurchaseOrder.instructions,
      fabricator: requestedPurchaseOrder.fabricator.$id,
      purchaseDetails: JSON.parse(requestedPurchaseOrder.purchaseDetails),
      orderDate: (requestedPurchaseOrder.orderDate).split('T')[0],
      expectedCompletionDate: (requestedPurchaseOrder.expectedCompletionDate).split('T')[0],
      closingDetails: JSON.parse(requestedPurchaseOrder.closingDetails),
      status: requestedPurchaseOrder.status,
    };
  } catch (error) {
    console.error('Error parsing product:', error);
    return null; // or handle the error as appropriate for your application
  }
};

