import React, { useEffect, useState } from 'react';
import {
  deleteCategoryById,
  getProductCategories,
  getProductCategoryById,
} from '../../lib/appwrite/api';
import CreateProductCategoryForm from '../../forms/products/CreateProductCategoryForm';
import { productCategoriesGrid } from '../../constants/productCategories';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from '@syncfusion/ej2-react-grids';
import Modal from '../../components/SSUComponents/Modal';
import { categoryFormDataTemplate } from '../../dtos/dtos';
import { toast } from 'react-toastify';
import { Toolbar } from '@syncfusion/ej2/grids';
import { Button } from '../../components';
import RButton from '../../components/RButton';

const ProductCategories = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allProductCategories, setAllProductCategories] = useState();
  const [productCategoryData, setProductCategoryData] = useState();
  const editing = {
    allowDeleting: true,
    allowEditing: true,
  };
  const toolbarOptions = ['Search'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productCategories = await getProductCategories();
        setAllProductCategories(productCategories);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching product categories:', error);
      }
    };
    fetchData();
  }, []);

  const onEditCategory = (categoryId) => {
    const fetchData = async () => {
      try {
        const productCategory = await getProductCategoryById(categoryId);
        setProductCategoryData(productCategory);
        setShowModal(true);
      } catch (error) {
        console.error('Error fetching category:', error);
      }
    };
    if (categoryId) fetchData();
  };

  const onDeleteCategory = async (categoryId) => {
    try {
      const response = await deleteCategoryById(categoryId);

      if (response.status === 'ok') {
        setAllProductCategories((prevAAllProductCategories) =>
          prevAAllProductCategories.filter(
            (category) => category.$id !== categoryId
          )
        );
        toast.success('Deleted Successfully!');
      } else {
        console.log('Deletion failed');
      }
    } catch (error) {
      console.error('Error deleting type:', error);
    }
  };

  const openCreateProductCategoryForm = () => {
    setProductCategoryData(categoryFormDataTemplate);
    setShowModal(true);
  };

  let grid;
  const created = () => {
    document
      .getElementById(grid.element.id + '_searchbar')
      .addEventListener('keyup', () => {
        grid.search(event.target.value);
      });
  };

  return (
    <>
      <div>
        <div className="flex justify-end">
          <RButton onClick={openCreateProductCategoryForm}>
            Ceate New Category
          </RButton>
        </div>
        {showModal && (
          <Modal setShowModal={setShowModal} title="Create Category">
            <CreateProductCategoryForm productCategory={productCategoryData} />
          </Modal>
        )}

        {!isLoading && (
          <div>
            <GridComponent
              id="gridcomp"
              dataSource={allProductCategories}
              width="auto"
              allowPaging
              allowSorting
              pageSettings={{ pageCount: 5 }}
              editSettings={editing}
              toolbar={toolbarOptions}
              ref={(g) => (grid = g)}
              created={created}
            >
              <ColumnsDirective>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {productCategoriesGrid.map((item, index) =>
                  item.field === 'action' ? (
                    <ColumnDirective
                      key={index}
                      {...item}
                      onEditCategory={onEditCategory}
                      onDeleteCategory={onDeleteCategory}
                    />
                  ) : (
                    <ColumnDirective key={index} {...item} />
                  )
                )}
              </ColumnsDirective>
              <Inject services={[Search, Page]} />
            </GridComponent>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductCategories;
