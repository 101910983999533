import React from "react";
import "./PurchaseOrderView.css";
import html2pdf from "html2pdf.js";
import {
  FirstPage,
  ImageSection,
} from "../components/SSUComponents/PurchaseOrderViewDivs";

const PurchaseOrderView = (props) => {
  console.log("Props in PurchaseOrderView: ",props)
  const downloadAsPDF = () => {
    const element = document.querySelector(".book");
    const opt = {
      margin: 0,
      filename: props.orderDetails.commonProductName+"_"+props.orderDetails.fabricator+".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
  };
  return (
    <>
      <div className="pt-24">
        <div className="flex justify-between items-end">
          <button onClick={props.onBack}>Back</button>
          <button onClick={downloadAsPDF}>Download as PDF</button>
        </div>

        <div className="book">
          <div className="page">
            <div className="subpage">
              <FirstPage orderDetails={props.orderDetails} />{" "}
            </div>
          </div>
          {props.orderDetails.productImages.map((url) => {
            return (
              <div className="page">
                <div className="subpage">
                  <ImageSection url={url}/>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderView;
