import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import {
  AdminDashboard,
  Barcodes,
  CreateProduct,
  CreateFabricator,
  Products,
  PurchaseOrders,
  Fabricators,
  CreateFabric,
  Fabric,
  CreateProductCategoryAndTypes,
  CreatePurchaseOrders,
} from "./pages";
import "./App.css";

import FabricatorView from "./pages/FabricatorView";
import CreateProductTypes from "./pages/Products/ProductTypes";
import ProductCategories from "./pages/Products/ProductCategories";
import FabricatorLayout from "./_fabricator/FabricatorLayout";
import AdminLayout from "./_admin/AdminLayout";

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route
            path="/my-orders/:fabricatorId"
            element={
              <FabricatorLayout>
                <FabricatorView />
              </FabricatorLayout>
            }
          />

          <Route element={<AdminLayout />}>
            {/* Dashboard */}
            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/" element={<AdminDashboard />} />

            {/* Product Routes */}
            <Route path="/products" element={<Products />} />
            <Route path="/barcodes" element={<Barcodes />} />
            <Route path="/create-product" element={<CreateProduct />} />

            <Route
              path="/create-product/:productId"
              element={<CreateProduct />}
            />

            <Route
              path="/create-product-category"
              element={<CreateProductCategoryAndTypes />}
            />

            <Route path="/product-categories" element={<ProductCategories />} />

            <Route
              path="/product-categories/:categoryId"
              element={<ProductCategories />}
            />

            <Route path="/product-types" element={<CreateProductTypes />} />

            {/* Product Routes Ends */}
            <Route
              path="/create-purchase-order"
              element={<CreatePurchaseOrders />}
            />
            <Route
              path="/create-purchase-order/:purchaseOrderId"
              element={<CreatePurchaseOrders />}
            />

            <Route path="/purchase-orders" element={<PurchaseOrders />} />
            {/* <Route path="/create-fabricator" element={<CreateFabricator />} />
            <Route
              path="/create-fabricator/:fabricatorId"
              element={<CreateFabricator />}
            /> */}
            <Route path="/fabricators" element={<Fabricators />} />
            <Route path="/create-fabric" element={<CreateFabric />} />
            <Route path="/fabric" element={<Fabric />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
