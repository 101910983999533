import React, { useState } from "react";
import { createType, updateType } from "../../lib/appwrite/api";
import { toast } from "react-toastify";

const CreateProductTypeForm = (props) => {
  const { productType } = props;
  const [productTypeData, setProductTypeData] = useState(productType);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createType(productTypeData);

      if (response.$id) {
        toast.success("Product type created successfully");
        //update local state also.
        /*This needs to be handled while using redux store.*/
      } else {
        console.log("Creation Failed");
      }
    } catch (error) {
      console.error("Error creating type:", error);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProductTypeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleVariationChange = (e, index) => {
    const { name, value } = e.target;

    setProductTypeData((prevState) => {
      const updatedVariations = [...prevState.variations];
      updatedVariations[index] = {
        ...updatedVariations[index],
        [name]:
          name === "options"
            ? value.split(",").map((option) => option.trim())
            : value,
      };
      return {
        ...prevState,
        variations: updatedVariations,
      };
    });
  };

  const handleTypeUpdate = async () => {
    console.log("You need to add api to update Type");
    const typeId = productType.$id;

    try {
      const response = await updateType(productTypeData, typeId);

      if (response.$id) {
        toast.success("Type Updated!");
        //update local state also.
        /*This needs to be handled while using redux store.*/
      } else {
        console.log("Updation Failed");
      }
    } catch (error) {
      console.error("Error updatingtype:", error);
    }
  };

  return (
    <>
      <form className="p-10 bg-white" onSubmit={onSubmit}>
        {/* Div for name, type, category */}
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="product-type"
            >
              Type
            </label>
            <input
              id="product-type"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="name"
              value={productTypeData.name}
              onChange={(e) => handleChange(e, "type")}
            />
          </div>
        </div>

        <div>
          {productTypeData.variations.map((variation, index) => (
            <div key={index} className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Variation Name:
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  name="name"
                  value={variation.name}
                  onChange={(e) => handleVariationChange(e, index)}
                />
              </div>

              {/* Correction: Use the variation.options for the input value */}
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Variation Options:
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  name="options"
                  value={variation.options.join(", ")} // Convert array to string for display
                  onChange={(e) => handleVariationChange(e, index)}
                />
              </div>
            </div>
          ))}
        </div>

        {/* <button type="submit">Submit</button> */}
        {productType.$id ? (
          <button
            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={handleTypeUpdate}
          >
            Update
          </button>
        ) : (
          <button
            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="submit"
          >
            Submit
          </button>
        )}

        {/* <pre>{JSON.stringify(productTypeData, null, 2)}</pre> */}
      </form>
    </>
  );
};

export default CreateProductTypeForm;
