import { MdDelete, MdModeEdit } from "react-icons/md";

const colorPallete = [
  "#E74C3C",
  "#8E44AD",
  "#F39C12",
  "#3498DB",
  "#1ABC9C",
  "#28B463",
  "#F1C40F",
];

const productDetailsTemplate = (props) => {
  const colors = colorPallete.length;
  const randomNum = Math.floor(Math.random() * colors);

  return (
    <div className="flex flex-wrap">
      {props.products.map((product, index) => {
        return (
          <span
            key={index}
            style={{
              background: colorPallete[(randomNum + index) % colors],
            }}
            className="text-white py-1 px-2 capitalize rounded-2xl text-xs m-1"
          >
            {product.productName}
          </span>
        );
      })}
    </div>
  );
};

const optionsTemplate = (props) => {
  console.log("M: props expecting a function too: ", props);
  return (
    <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
      <p className="flex items-center gap-2 text-gray-700 hover:drop-shadow-xl text-xl cursor-pointer">
        <span>
          <MdModeEdit onClick={() => props.column.onEditCategory(props.$id)} />
        </span>
      </p>
      <p className="flex items-center gap-2 text-red-400 hover:drop-shadow-xl text-xl cursor-pointer">
        <span>
          <MdDelete onClick={() => props.column.onDeleteCategory(props.$id)} />
        </span>
      </p>
    </div>
  );
};

export const productCategoriesGrid = [
  {
    field: "name",
    headerText: "Category",
    width: "100",
    textAlign: "Left",
  },
  {
    headerText: "Products",
    template: productDetailsTemplate,
    textAlign: "Center",
    width: "200",
  },
  {
    field: "action",
    headerText: "Action",
    template: optionsTemplate,
    width: "150",
    textAlign: "Center",
  },
];
