import React, { useEffect, useState } from "react";
import CreateProductForm from "../../forms/products/CreateProductForm";
import { useParams } from "react-router-dom";
import { getProductById } from "../../lib/appwrite/api";
import { productFormDataTemplate } from "../../dtos/dtos";
import Loader from "../../components/Loader";

const CreateProduct = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData = await getProductById(productId);
        setProduct(productData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setIsLoading(false);
        // Optionally handle the error as needed, e.g., set a default state or show a message.
      }
    };

    if (productId) {
      fetchData();
    } else {
      setProduct(productFormDataTemplate);
      setIsLoading(false);
    }
  }, [productId]);

  if (isLoading) {
    // Optionally, you can display a loading spinner or message here.
    return <Loader />;
  }

  return <CreateProductForm product={product} />;
};

export default CreateProduct;
