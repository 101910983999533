import { MdDelete, MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import noImage from "../assets/noImage.jpeg";

const purchaseOrderGridStatus = (props) => (
  <span
    style={{
      background: props.status == "Closed" ? "green" : "#F39C12",
    }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-xs m-1"
  >
    {props.status}
  </span>
);

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl w-20 h-20 md:ml-3 object-cover"
      src={props.productImage ? props.productImage : noImage}
      alt="order-item"
    />
    {/* <div>{props}</div> */}
  </div>
);

const optionsTemplate = (props) => {
  console.log("M: props expecting a function too: ", props);
  return (
    <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
      <p className="flex items-center gap-2 text-gray-700 hover:drop-shadow-xl text-xl cursor-pointer">
        <Link
          target="_blank"
          to={`/create-purchase-order/${props.$id}`}
          className="flex items-center gap-2 text-gray-700 hover:drop-shadow-xl text-xl cursor-pointer"
        >
          <span>
            <MdModeEdit />
          </span>
        </Link>
      </p>
      <p className="flex items-center gap-2 text-red-400 hover:drop-shadow-xl text-xl cursor-pointer">
        <span>
          <MdDelete
            onClick={() => props.column.onDeletePurchaseOrder(props.$id)}
          />
        </span>
      </p>
    </div>
  );
};

export const purchaseOrderGrid = [
  {
    headerText: "Image",
    template: gridOrderImage,
    textAlign: "Center",
    width: "100",
  },
  {
    field: "commonProductName",
    headerText: "Item",
    width: "120",
    //editType: 'dropdownedit',
    textAlign: "Center",
  },
  {
    field: "total",
    headerText: "Total",
    width: "60",
    textAlign: "Center",
  },
  {
    field: "fabricator",
    headerText: "Fabricator",
    width: "100",
    format: "C2",
    textAlign: "Center",
  },
  {
    field: "orderDate",
    headerText: "OrderDate",
    width: "100",
    format: "MM/dd/yyyy",
    textAlign: "Center",
  },
  {
    field: "expectedCompletionDate",
    headerText: "Completion Date",
    width: "100",
    format: "yMd",
    textAlign: "Center",
  },
  {
    field: "status",
    headerText: "Status",
    width: "80",
    format: "yMd",
    textAlign: "Center",
    template: purchaseOrderGridStatus,
  },
  {
    field: "action",
    headerText: "Action",
    template: optionsTemplate,
    width: "100",
    textAlign: "Center",
  },
];
