import React, { useEffect, useState } from 'react';
import CreateProductTypeForm from '../../forms/products/CreateProductTypeForm';
import Modal from '../../components/SSUComponents/Modal';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from '@syncfusion/ej2-react-grids';
import { productTypesGrid } from '../../constants/productTypes';
import { deleteTypeById, getType, getTypeById } from '../../lib/appwrite/api';
import { typeFormDataTemplate } from '../../dtos/dtos';
import { toast } from 'react-toastify';
import { Toolbar } from '@syncfusion/ej2/grids';
import RButton from '../../components/RButton';

const ProductTypes = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [allProductTypes, setAllProductTypes] = useState();
  const [productTypeData, setProductTypeData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const editing = { allowDeleting: true, allowEditing: true };
  const toolbarOptions = ['Search'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productTypes = await getType();
        setAllProductTypes(productTypes);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching product types:', error);
      }
    };
    fetchData();
  }, []);

  const onEditType = (typeId) => {
    const fetchData = async () => {
      try {
        const productType = await getTypeById(typeId);
        setProductTypeData(productType);
        setShowModal(true);
      } catch (error) {
        console.error('Error fetching type:', error);
      }
    };
    if (typeId) fetchData();
  };

  const onDeleteType = async (typeId) => {
    try {
      const response = await deleteTypeById(typeId);

      if (response.status === 'ok') {
        //This needs to be handled while using redux store.
        setAllProductTypes((prevAllProductTypes) =>
          prevAllProductTypes.filter((type) => type.$id !== typeId)
        );

        toast.success('Deleted Successfully!');
      } else {
        console.log('Deletion failed');
      }
    } catch (error) {
      console.error('Error deleting type:', error);
    }
  };

  const openCreateProductTypeForm = () => {
    setProductTypeData(typeFormDataTemplate);
    setShowModal(true);
  };

  //Following code searches grid on each key stroke
  let grid;
  const created = () => {
    document
      .getElementById(grid.element.id + '_searchbar')
      .addEventListener('keyup', () => {
        grid.search(event.target.value);
      });
  };

  return (
    <>
      <div>
        <div className="flex justify-end">
          <RButton onClick={openCreateProductTypeForm}>Ceate New Type</RButton>
        </div>
        {showModal && (
          <Modal setShowModal={setShowModal} title="Create Type">
            <CreateProductTypeForm productType={productTypeData} />
          </Modal>
        )}
        {!isLoading && (
          <div>
            <GridComponent
              id="gridcomp"
              dataSource={allProductTypes}
              width="auto"
              allowPaging
              allowSorting
              pageSettings={{ pageCount: 5 }}
              editSettings={editing}
              toolbar={toolbarOptions}
              ref={(g) => (grid = g)}
              created={created}
            >
              <ColumnsDirective>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {productTypesGrid.map((item, index) =>
                  item.field === 'options' ? (
                    <ColumnDirective
                      key={index}
                      {...item}
                      onEditType={onEditType}
                      onDeleteType={onDeleteType}
                    />
                  ) : (
                    <ColumnDirective key={index} {...item} />
                  )
                )}
              </ColumnsDirective>
              <Inject services={[Search, Page, Toolbar]} />
            </GridComponent>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductTypes;
