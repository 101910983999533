import React, { useEffect, useState } from 'react';
import CustomListRow from '../../components/SSUComponents/CustomListRow';

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
} from '@syncfusion/ej2-react-grids';
import {
  deletePurchaseOrderById,
  getPurchaseOrders,
} from '../../lib/appwrite/api';
import { purchaseOrderGrid } from '../../constants/purchaseOrder';
import { formatDate } from '../../utils/utils';
import { toast } from 'react-toastify';
import RButton from '../../components/RButton';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';

const PurchaseOrders = () => {
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseOrdersGridData, setPuchaseOrdersGridData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const purchaseOrders = await getPurchaseOrders();
        console.log('PurchaseOrders:', purchaseOrders);
        const purchaseOrdersGridData = purchaseOrders.map((purchaseOrder) => {
          return {
            $id: purchaseOrder.$id,
            commonProductName: purchaseOrder.commonProductName,
            total: purchaseOrder.purchaseDetails.total,
            fabricator: purchaseOrder.fabricator?.name,
            orderDate: formatDate(purchaseOrder.orderDate),
            expectedCompletionDate: formatDate(
              purchaseOrder.expectedCompletionDate
            ),
            status: purchaseOrder.status,
            productImage:
              purchaseOrder.product?.images &&
              purchaseOrder.product.images.length > 0 &&
              JSON.parse(purchaseOrder.product.images)[0],
            statusBg: '#8BE78B',
          };
        });
        setPuchaseOrdersGridData(purchaseOrdersGridData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, []);

  //Following code searches grid on each key stroke
  let grid;
  const created = () => {
    document
      .getElementById(grid.element.id + '_searchbar')
      .addEventListener('keyup', () => {
        grid.search(event.target.value);
      });
  };
  //delete purchaseOrder
  const onDeletePurchaseOrder = async (purchaseOrderId) => {
    try {
      const response = await deletePurchaseOrderById(purchaseOrderId);

      if (response.status === 'ok') {
        setPuchaseOrdersGridData((prevPuchaseOrdersGridData) =>
          prevPuchaseOrdersGridData.filter(
            (purchaseOrder) => purchaseOrder.$id !== purchaseOrderId
          )
        );
        toast.success('Purchase order Deleted Successfully!');
      } else {
        console.log('Deletion failed');
      }
    } catch (error) {
      console.error('Error deleting type:', error);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <div className="flex justify-end mb-3">
        <Link to="/create-purchase-order" target="_blank">
          <RButton>Ceate New Purchase Order</RButton>
        </Link>
      </div>
      <GridComponent
        dataSource={purchaseOrdersGridData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
        ref={(g) => (grid = g)}
        created={created}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {purchaseOrderGrid.map((item, index) =>
            item.field === 'action' ? (
              <ColumnDirective
                key={index}
                {...item}
                onDeletePurchaseOrder={onDeletePurchaseOrder}
              />
            ) : (
              <ColumnDirective key={index} {...item} />
            )
          )}
        </ColumnsDirective>
        <Inject
          services={[Page, Selection, Toolbar, Edit, Sort, Search, Filter]}
        />
      </GridComponent>
    </div>
  );
};

export default PurchaseOrders;
